import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Button } from '../../atoms';
import { navigate } from '../../../utils/dom';

// ========== TYPES ========== //

type PotentialError = Error | null | undefined;

interface ComponentProps {
  error: PotentialError | PotentialError[];
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '1rem',

      height: '100%',
      margin: theme.spacing(2, 0),
    },
  })
);

// ========== COMPONENT ========== //

export default function GenericError({ error }: ComponentProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Box>
        {Array.isArray(error) ? (
          error
            .filter((err) => err)
            .map((err, i) => (
              <Typography
                key={`${(err as Error).message}${i}`}
                variant="h6"
                color="error"
              >
                {(err as Error).message}
              </Typography>
            ))
        ) : error ? (
          <Typography>{error.message}</Typography>
        ) : null}
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/')}
        >
          <Typography>Go to Homepage</Typography>
        </Button>
      </Box>
    </Box>
  );
}
