/**
 * Layout component that queries for data with Gatsby's useStaticQuery
 * component.
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import { Box } from '@material-ui/core';

interface ComponentProps {
  title?: string;
  children?: React.ReactNode;
}

export default function Layout({ children }: ComponentProps) {
  return <Box>{children}</Box>;
}
