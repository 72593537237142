import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#F8F8F8',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        padding: theme.spacing(4, 2.5),
      },
    },
    title: {
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
    contentCtn: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: theme.spacing(4, 2.5),
      maxWidth: theme.custom.container1460,
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
    listings: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(9),
      },
    },
    individualListings: {},
    greenText: {
      color: '#2CA792',
    },
    cardCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      textAlign: 'center',
    },
    card: {
      minWidth: 'calc(50vw - 24px)',

      '&:last-child': {
        marginRight: 20,
      },
    },
    cardHorizontal: {
      display: 'flex',
      gap: theme.spacing(1),
      overflowX: 'auto',
      marginRight: -20,
      scrollSnapType: 'x proximity',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    slider: {
      padding: 0,
      maxWidth: 150,
    },
    sliderTrack: {
      height: 3,
    },
    sliderRail: {
      height: 3,
    },
    sliderThumb: {
      width: 7,
      height: 7,
      top: '50%',
      transform: 'translate(-50%, -50%)',
      margin: 0,
    },
    wrapSelect: {
      marginBottom: 0,
    },
    select: {
      [theme.breakpoints.down('md')]: {
        height: 40,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: 'none',
      },
    },
    filterCtn: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    propertiesCount: {},
    propertiesCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.5),
    },
    gridHeading: {
      color: theme.palette.gray.dark,
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 16,
      width: '100%',
      paddingTop: theme.spacing(1),
      margin: theme.spacing(1.5, 0, 1.5, 0),
    },
  })
);

export default useStyles;
