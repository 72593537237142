import * as React from 'react';
import { Box, Breadcrumbs } from '@material-ui/core';
import useStyles from './AccountLayout.styles';
import { Spacer } from '../../atoms';
import useIsScreenType from '../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../config';

export interface AccountLayoutProps {
  leftHandSide: React.ReactNode;
  rightHandSide: React.ReactNode;

  /**
   * If provided, they will be put inside MUI's Breadcrumbs component.
   */
  breadcrumbsContent?: React.ReactNode;

  /**
   * If provided, a cover image will be shown.
   */
  coverImageSrc?: string;
}

export default function AccountLayout({
  leftHandSide,
  rightHandSide,
  breadcrumbsContent,
  coverImageSrc,
}: AccountLayoutProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({ isMobile });

  const breadcrumbs = !isMobile && breadcrumbsContent && (
    <>
      <Breadcrumbs>{breadcrumbsContent}</Breadcrumbs>
      <Spacer y={3.5} />
    </>
  );

  const coverImage = !isMobile && coverImageSrc && (
    <img className={classes.coverImage} alt="cover image" src={coverImageSrc} />
  );

  return (
    <Box className={classes.accountLayoutWrapper}>
      <Box className={classes.accountLayoutCtn}>
        {!isMobile && (
          <Box className={classes.leftHandSideCtn}>{leftHandSide}</Box>
        )}

        <Box className={classes.rightHandSideCtn}>
          {breadcrumbs}
          {coverImage}
          <Box className={classes.rightHandSideContentCtn}>{rightHandSide}</Box>
        </Box>
      </Box>
    </Box>
  );
}
