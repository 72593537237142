import { Box, Grid, Hidden } from '@material-ui/core';
import {
  ArrowRight as ArrowRightIcon,
  Place as PlaceIcon,
} from '@material-ui/icons';
import * as React from 'react';
import ArrowIcon from '../../../images/icons/Arrow';
import PropertyFloorIcon from '../../../images/icons/property/PropertyFloorIcon';
import PropertyImageIcon from '../../../images/icons/property/PropertyImageIcon';
import PropertyVideoIcon from '../../../images/icons/property/PropertyVideoIcon';
import { formatNumber } from '../../../utils/number';
import { Link, Typography } from '../../atoms';
import CloudinaryPicture from '../../atoms/CloudinaryPicture/CloudinaryPicture';
import { getPropertyImageCloudinaryPictureSizeConfig } from '../../pages/properties/PropertyImage/propertyImageConfig';
import { PropertyListMobileViewMode } from '../../pages/properties/PropertyListPage';
import useStyles from './AnalyticsPropertyCard.styles';

export interface AnalyticsPropertyCardProps {
  developmentId: number;
  title: string;
  rentPcm: number;
  imgThumbnailSrc: string;
  promotion: string;
  postcode: string | null;
  address: string | null;
  analytics: {
    viewCount7day: number | null;
    viewCountChange7day: number | null;
    leadCount7day: number | null;
    leadCountChange7day: number | null;
    averageEngagementTime7day: number | null;
    averageEngagementTimeChange7day: number | null;
  };
}

export default function AnalyticsPropertyCard({
  developmentId,
  title,
  rentPcm,
  imgThumbnailSrc,
  promotion,
  postcode,
  address,
  analytics: {
    viewCount7day,
    viewCountChange7day,
    leadCount7day,
    leadCountChange7day,
    averageEngagementTime7day,
    averageEngagementTimeChange7day,
  },
}: AnalyticsPropertyCardProps) {
  const classes = useStyles();
  const cloudinaryPictureConfig = getPropertyImageCloudinaryPictureSizeConfig(
    PropertyListMobileViewMode.NARROW
  );

  const buttons = (
    <Box className={classes.buttons}>
      <Link to={`/my-account/property/edit/${developmentId}`}>
        <Typography
          className={classes.editListing}
          fontSize={14}
          fontWeight={600}
        >
          Edit listing
        </Typography>
      </Link>
      <Link to="#">
        <Typography fontSize={14} fontWeight={600} color="primary">
          Analytics
        </Typography>
      </Link>
    </Box>
  );

  return (
    <Box className={classes.ctn}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={7} className={classes.info}>
          <Box className={classes.imgCtn}>
            <CloudinaryPicture
              imgSrc={imgThumbnailSrc}
              imgAlt={title}
              config={cloudinaryPictureConfig}
              imgProps={{
                height: '100%',
                width: '100%',
              }}
            />
            <Box className={classes.promotion}>
              <Typography
                fontSize={11}
                fontWeight={600}
                color="primary"
                colorVariant="contrastText"
              >
                {promotion}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.contentCtn}>
            <Box className={classes.title}>
              <Typography
                fontWeight={600}
                color="gray"
                colorVariant="contrastText"
                className={classes.titleText}
              >
                {title}
              </Typography>
              <Hidden mdUp>
                <Typography
                  fontWeight="bold"
                  color="primary"
                  component="span"
                  className={classes.rentPcm}
                >
                  £{formatNumber(rentPcm)}
                </Typography>
                <Typography
                  color="gray"
                  colorVariant="contrastText"
                  component="span"
                  className={classes.rentPcm}
                >
                  /month
                </Typography>
              </Hidden>
            </Box>
            <Box className={classes.address}>
              <PlaceIcon className={classes.icon} />
              <Typography
                fontWeight={600}
                color="gray"
                colorVariant="light1"
                component="span"
              >
                {address}, {postcode}
              </Typography>
            </Box>
            <Hidden smDown>
              <Grid container className={classes.gridRow}>
                <Grid item xs={4} className={classes.gridCenterBaseLine}>
                  <PropertyImageIcon /> 10
                </Grid>
                <Grid item xs={4} className={classes.gridCenterBaseLine}>
                  <PropertyVideoIcon /> 7
                </Grid>
                <Grid item xs={4} className={classes.gridCenterBaseLine}>
                  <PropertyFloorIcon /> 2
                </Grid>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Box mb={1.5}>
                <Typography
                  fontWeight="bold"
                  color="primary"
                  component="span"
                  className={classes.rentPcm}
                >
                  £{formatNumber(rentPcm)}
                </Typography>
                <Typography
                  color="gray"
                  colorVariant="contrastText"
                  component="span"
                  className={classes.rentPcm}
                >
                  /month
                </Typography>
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box className={classes.analytics}>
                <Box className={classes.analyticItem}>
                  <Box display="flex" alignItems="center">
                    <ArrowRightIcon className={classes.arrowIcon} />
                    <Typography
                      fontSize={12}
                      color="gray"
                      colorVariant="contrastText"
                    >
                      7-day views
                    </Typography>
                  </Box>
                  <Typography fontSize={12} color="gray" colorVariant="light1">
                    {viewCount7day} (
                    {viewCountChange7day && (
                      <>
                        {viewCountChange7day < 0 ? (
                          <ArrowIcon className={classes.downIcon} />
                        ) : (
                          <ArrowIcon className={classes.upIcon} />
                        )}
                        {Math.abs(viewCountChange7day)}%
                      </>
                    )}
                    )
                  </Typography>
                </Box>
                <Box className={classes.analyticItem}>
                  <Box display="flex" alignItems="center">
                    <ArrowRightIcon className={classes.arrowIcon} />
                    <Typography
                      fontSize={12}
                      color="gray"
                      colorVariant="contrastText"
                    >
                      7-day leads
                    </Typography>
                  </Box>
                  <Typography fontSize={12} color="gray" colorVariant="light1">
                    {leadCount7day} (
                    {leadCountChange7day && (
                      <>
                        {leadCountChange7day < 0 ? (
                          <ArrowIcon className={classes.downIcon} />
                        ) : (
                          <ArrowIcon className={classes.upIcon} />
                        )}
                        {Math.abs(leadCountChange7day)}%
                      </>
                    )}
                    )
                  </Typography>
                </Box>
                <Box className={classes.analyticItem}>
                  <Box display="flex" alignItems="center">
                    <ArrowRightIcon className={classes.arrowIcon} />
                    <Typography
                      fontSize={12}
                      color="gray"
                      colorVariant="contrastText"
                    >
                      Avg visit time
                    </Typography>
                  </Box>
                  <Typography fontSize={12} color="gray" colorVariant="light1">
                    {averageEngagementTime7day} (
                    {averageEngagementTimeChange7day && (
                      <>
                        {averageEngagementTimeChange7day < 0 ? (
                          <ArrowIcon className={classes.downIcon} />
                        ) : (
                          <ArrowIcon className={classes.upIcon} />
                        )}
                        {Math.abs(averageEngagementTimeChange7day)}%
                      </>
                    )}
                    )
                  </Typography>
                </Box>
              </Box>
            </Hidden>
            <Hidden smDown>{buttons}</Hidden>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid item container xs={12} md={5} className={classes.reportCard}>
            <Grid item xs={4} className={classes.gridCenter}>
              <div className={classes.alignRow}>
                <Typography color="gray" colorVariant="dark" fontWeight={600}>
                  {viewCount7day} (
                  {viewCountChange7day && (
                    <>
                      {viewCountChange7day < 0 ? (
                        <ArrowIcon className={classes.downIcon} />
                      ) : (
                        <ArrowIcon className={classes.upIcon} />
                      )}
                      {Math.abs(viewCountChange7day)}%
                    </>
                  )}
                  )
                </Typography>
                <Typography color="gray" colorVariant="dark" fontWeight={600}>
                  vs. last period
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} className={classes.gridCenter}>
              <div className={classes.alignRow}>
                <Typography color="gray" colorVariant="dark" fontWeight={600}>
                  {leadCount7day} (
                  {leadCountChange7day && (
                    <>
                      {leadCountChange7day < 0 ? (
                        <ArrowIcon className={classes.downIcon} />
                      ) : (
                        <ArrowIcon className={classes.upIcon} />
                      )}
                      {Math.abs(leadCountChange7day)}%
                    </>
                  )}
                  )
                </Typography>
                <Typography color="gray" colorVariant="dark" fontWeight={600}>
                  vs. last period
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} className={classes.gridCenter}>
              <div className={classes.alignRow}>
                <Typography color="gray" colorVariant="dark" fontWeight={600}>
                  {averageEngagementTime7day} (
                  {averageEngagementTimeChange7day && (
                    <>
                      {averageEngagementTimeChange7day < 0 ? (
                        <ArrowIcon className={classes.downIcon} />
                      ) : (
                        <ArrowIcon className={classes.upIcon} />
                      )}
                      {Math.abs(averageEngagementTimeChange7day)}%
                    </>
                  )}
                  )
                </Typography>
                <Typography color="gray" colorVariant="dark" fontWeight={600}>
                  vs. last period
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <Hidden mdUp>{buttons}</Hidden>
    </Box>
  );
}
