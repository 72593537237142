import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

const Email = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 21 14.011" {...props}>
      <path
        d="M105.98,284.333a3.571,3.571,0,0,1,.347-.59,1.068,1.068,0,0,1,.861-.3q3.885,0,7.771,0h10.826a1.1,1.1,0,0,1,1.176.894,1.55,1.55,0,0,1,.018.285q0,5.825,0,11.649a1.089,1.089,0,0,1-1.172,1.18q-9.329,0-18.659,0c-.6,0-.9-.233-1.168-.891Q105.98,290.445,105.98,284.333Zm20.17,11.7V284.781c-.083.059-.144.1-.2.146q-4.133,3.1-8.266,6.2a1.909,1.909,0,0,1-2.406,0l-8.282-6.211c-.057-.043-.116-.083-.187-.134v11.251c.081-.1.138-.162.193-.229l3.728-4.606c.2-.25.4-.5.61-.746a.392.392,0,0,1,.553-.042.4.4,0,0,1,.073.536,1.152,1.152,0,0,1-.087.114l-4.347,5.371c-.049.06-.1.123-.152.2h18.2c-.05-.068-.085-.118-.123-.164q-1.335-1.65-2.671-3.3-.871-1.076-1.741-2.152a.408.408,0,0,1,.126-.666c.189-.079.359-.018.53.194l3.16,3.9Zm-18.663-11.774c.052.047.071.066.091.082q4.122,3.094,8.245,6.186a1.089,1.089,0,0,0,1.334-.017q4.091-3.066,8.181-6.134a.808.808,0,0,1,.134-.067l-.05-.049Z"
        transform="translate(-105.98 -283.44)"
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  );
};

export default Email;
