import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

const Star = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon width="21" height="20.149" viewBox="0 0 21 20.149" {...props}>
      <g transform="translate(-116.639 -221.722)">
        <path
          d="M119.983,240.158c.09-.533.179-1.07.271-1.606.2-1.185.4-2.371.614-3.556a.5.5,0,0,0-.165-.5q-1.771-1.713-3.53-3.441a1.683,1.683,0,0,1-.29-2.1,1.652,1.652,0,0,1,1.225-.812c1.639-.241,3.278-.482,4.917-.714a.461.461,0,0,0,.39-.289q1.086-2.221,2.185-4.436a1.67,1.67,0,0,1,2.5-.655,2.047,2.047,0,0,1,.583.705c.738,1.449,1.452,2.91,2.165,4.371a.494.494,0,0,0,.427.306q2.438.343,4.873.71a1.626,1.626,0,0,1,1.392,1.148,1.608,1.608,0,0,1-.425,1.753q-1.729,1.709-3.483,3.391a.647.647,0,0,0-.215.657c.283,1.578.548,3.16.818,4.741a1.706,1.706,0,0,1-2.522,1.808c-1.425-.75-2.853-1.5-4.274-2.254a.554.554,0,0,0-.589-.006q-2.162,1.153-4.336,2.282a1.69,1.69,0,0,1-2.436-1A4.927,4.927,0,0,1,119.983,240.158Zm1.478-.041c-.016.231.157.35.384.249.049-.021.094-.048.141-.073,1.419-.745,2.84-1.487,4.255-2.239a1.814,1.814,0,0,1,1.817.009q2.152,1.146,4.317,2.267a.388.388,0,0,0,.326.021.373.373,0,0,0,.1-.312c-.263-1.589-.527-3.179-.816-4.763a1.88,1.88,0,0,1,.581-1.828c1.159-1.11,2.3-2.236,3.447-3.361.081-.08.173-.243.142-.319a.444.444,0,0,0-.309-.2c-1.5-.226-2.989-.47-4.491-.645a2.168,2.168,0,0,1-1.914-1.36c-.5-1.125-1.08-2.215-1.626-3.32-.147-.3-.289-.6-.446-.893-.119-.221-.33-.217-.458,0-.031.052-.056.107-.083.161-.705,1.431-1.416,2.859-2.114,4.294a1.825,1.825,0,0,1-1.5,1.068q-2.393.331-4.782.7a.445.445,0,0,0-.31.2c-.031.075.063.235.143.318.316.327.648.637.973.954.84.819,1.676,1.643,2.521,2.457a1.757,1.757,0,0,1,.555,1.645c-.155.917-.316,1.833-.474,2.75C121.714,238.63,121.588,239.368,121.46,240.118Z"
          transform="translate(0)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
};

export default Star;
