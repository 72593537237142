import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

const PhoneCall = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 21.363 21.364" {...props}>
      <g transform="matrix(1, 0.017, -0.017, 1, -49.66, -170.799)">
        <path
          d="M67.438,190.9c-.255-.041-.511-.078-.765-.123a7.364,7.364,0,0,1-3.039-1.306,39.679,39.679,0,0,1-6.09-5.376,33.347,33.347,0,0,1-3.439-4.327,6.953,6.953,0,0,1-1.07-3.17c-.007-.073-.023-.146-.034-.218v-.943c.034-.235.061-.471.1-.7a7.33,7.33,0,0,1,1.581-3.455,3.445,3.445,0,0,1,1.342-1.109c.282-.112.581-.18.872-.267h.7c.184.046.37.083.55.139a3.051,3.051,0,0,1,1.273.793c.5.5,1.006,1,1.507,1.508a2.984,2.984,0,0,1-.008,4.353c-.313.315-.629.627-.942.942a1.173,1.173,0,0,0-.059,1.747,40.278,40.278,0,0,0,4.6,4.6,1.174,1.174,0,0,0,1.747-.059c.374-.37.736-.753,1.123-1.109a2.953,2.953,0,0,1,4.006.007c.617.58,1.209,1.187,1.8,1.794a2.8,2.8,0,0,1,.772,1.595.422.422,0,0,0,.036.092v.7a.4.4,0,0,0-.037.091,2.84,2.84,0,0,1-.813,1.638,7.125,7.125,0,0,1-3.522,1.964c-.384.087-.776.137-1.165.2Zm-7.1-14.8c.037-.034.068-.059.1-.087a2.137,2.137,0,0,0,.052-2.938c-.581-.626-1.186-1.232-1.813-1.811a2.141,2.141,0,0,0-2.995.13,6.437,6.437,0,0,0-1.8,3.67,6.145,6.145,0,0,0,.9,4.239,30.9,30.9,0,0,0,3.361,4.234,38.32,38.32,0,0,0,6.02,5.3,6.369,6.369,0,0,0,5.074,1.112,6.192,6.192,0,0,0,2.58-1.148l-4.656-4.655c-.1.114-.214.252-.34.377a1.982,1.982,0,0,1-2.833.086,41.655,41.655,0,0,1-4.693-4.692,1.982,1.982,0,0,1,.1-2.863c.119-.119.256-.221.416-.358-.1-.083-.163-.121-.211-.169q-1.2-1.194-2.394-2.391a1.446,1.446,0,0,1-.151-.167.4.4,0,0,1,.551-.571,1.205,1.205,0,0,1,.169.148q1.2,1.2,2.393,2.393C60.221,175.993,60.276,176.043,60.337,176.1Zm7.482,7.442,4.675,4.678a.437.437,0,0,0,.05-.039,2.128,2.128,0,0,0,.121-2.917,26.289,26.289,0,0,0-1.881-1.883A2.118,2.118,0,0,0,67.819,183.545Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M198.4,481.05a1.227,1.227,0,0,1-.3-.177q-1.487-1.434-2.96-2.884a.512.512,0,1,1,.72-.717q1.464,1.421,2.925,2.848a.534.534,0,0,1,.177.618A.52.52,0,0,1,198.4,481.05Z"
          transform="translate(-136.383 -295.125)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M168.89,448.346a.5.5,0,0,1,.5-.5.525.525,0,0,1,.508.53.531.531,0,0,1-.5.488A.52.52,0,0,1,168.89,448.346Z"
          transform="translate(-111.183 -266.662)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M123.415,226.443a.509.509,0,0,1-.507.5.507.507,0,0,1,.012-1.014A.511.511,0,0,1,123.415,226.443Z"
          transform="translate(-65.97 -53.168)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
};

export default PhoneCall;
