import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export default function Views(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 21 13.379" {...props}>
      <g transform="translate(-47.781 -359.21)">
        <g transform="translate(-26.139 109.396)">
          <path
            d="M73.92,256.382a4.516,4.516,0,0,1,.306-.5,18.663,18.663,0,0,1,5.258-4.632,10.076,10.076,0,0,1,4.384-1.414,8.928,8.928,0,0,1,4.151.753,15.7,15.7,0,0,1,4.967,3.482c.6.605,1.148,1.263,1.7,1.912a.715.715,0,0,1,0,1.052,18.674,18.674,0,0,1-5.317,4.717,9.659,9.659,0,0,1-5.322,1.435,9.4,9.4,0,0,1-3.752-1A18.035,18.035,0,0,1,74.125,257a2.522,2.522,0,0,1-.205-.374Zm1.565.137c.407.439.789.876,1.2,1.288a14.8,14.8,0,0,0,4.616,3.309,7.916,7.916,0,0,0,2.787.685,8.127,8.127,0,0,0,4.165-1.02,15.431,15.431,0,0,0,3.869-2.934c.421-.426.816-.878,1.231-1.328-.076-.09-.128-.161-.189-.223-.644-.66-1.258-1.351-1.939-1.97a12.784,12.784,0,0,0-4.322-2.68,7.234,7.234,0,0,0-4.955-.006,12.687,12.687,0,0,0-3.831,2.244A19.869,19.869,0,0,0,75.485,256.52Z"
            transform="translate(0 0)"
            fill={theme.palette.primary.main}
          />
          <path
            d="M202.283,299.319c2.617.011,4.732,1.727,4.707,3.819s-2.14,3.787-4.737,3.776c-2.618-.012-4.732-1.727-4.707-3.819S199.685,299.308,202.283,299.319Zm-.007,6.33c1.741-.009,3.142-1.141,3.139-2.535s-1.408-2.527-3.144-2.531-3.172,1.155-3.153,2.544A2.9,2.9,0,0,0,202.276,305.649Z"
            transform="translate(-117.85 -46.61)"
            fill={theme.palette.primary.main}
          />
        </g>
      </g>
    </SvgIcon>
  );
}
