import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    accountLayoutWrapper: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.gray.light2,
    },

    accountLayoutCtn: ({ isMobile }) => {
      const mobileDisplay = {
        display: 'flex',
        flexDirection: 'column' as React.CSSProperties['flexDirection'],
      };

      const nonMobileDisplay = {
        display: 'grid',
        gridTemplateColumns: `minmax(0, ${theme.spacing(
          26.25
        )}px) minmax(0, ${theme.spacing(127)}px)`,
        justifyContent: 'space-between',
        gap: theme.spacing(1),
        padding: theme.spacing(6, 2),
      };

      return {
        width: '100%',
        maxWidth: theme.spacing(170),
        ...(isMobile ? mobileDisplay : nonMobileDisplay),
      };
    },

    leftHandSideCtn: {
      width: '100%',
    },

    rightHandSideCtn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },

    coverImage: {
      width: '100%',
      height: theme.spacing(28.75),
      objectFit: 'cover',
    },

    rightHandSideContentCtn: {
      width: '100%',
    },
  })
);

export default useStyles;
