import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export default function Home(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 21 21" {...props}>
      <g id="home2" transform="translate(-105.43 -188.638)">
        <path
          id="Path_238"
          data-name="Path 238"
          d="M108.423,209.638a4.855,4.855,0,0,1-.714-.286,1.85,1.85,0,0,1-.963-1.661c-.01-2.632-.005-5.265,0-7.9a.656.656,0,1,1,1.311.015q0,3.856,0,7.713c0,.559.24.8.8.8h3.14v-7.1a1.979,1.979,0,0,1,2.117-2.115q1.845,0,3.69,0a1.972,1.972,0,0,1,2.065,2.064q0,3.445,0,6.89v.26h3.192a.668.668,0,0,0,.745-.75q0-5.522,0-11.045a.362.362,0,0,0-.148-.31q-3.609-3.01-7.213-6.027a.68.68,0,0,0-1.025,0q-4.339,3.626-8.676,7.254a.879.879,0,0,0-.094.08.694.694,0,0,1-1.219-.224v-.329a2.187,2.187,0,0,1,.293-.384q4.435-3.719,8.879-7.426a1.958,1.958,0,0,1,2.67.013l3.276,2.737.957.8c0-.406,0-.767,0-1.127a.657.657,0,1,1,1.311.008c0,.514.038,1.032-.01,1.542a1.158,1.158,0,0,0,.529,1.128c.965.753,1.89,1.557,2.826,2.347a.658.658,0,1,1-.839,1.012l-.209-.165V198.5q0,4.555,0,9.111a1.978,1.978,0,0,1-1.372,1.937c-.1.033-.205.062-.308.092Zm10.123-1.326c0-.056.006-.1.006-.135q0-3.527,0-7.054a.658.658,0,0,0-.712-.7q-1.876,0-3.751,0c-.545,0-.785.241-.785.791v7.1Z"
          fill={theme.palette.primary.main}
        />
        <path
          id="Path_239"
          data-name="Path 239"
          d="M273.845,464.378a.71.71,0,1,1,.714-.7A.716.716,0,0,1,273.845,464.378Z"
          transform="translate(-158.575 -259.324)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
}
