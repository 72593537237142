import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      padding: ({ isMobile }) => (isMobile ? theme.spacing(2.75, 2.25) : 0),
    },
    actionCtn: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
