import { Box, Grid, Hidden, Slider } from '@material-ui/core';
import * as React from 'react';
import { Select, SEO, Typography } from '../../../../../atoms';
import { GenericPage, Layout } from '../../../../../layout';
import { Pagination } from '../../../../../molecules';
import {
  AnalyticDetailLongCard,
  AnalyticListingCard,
} from '../../../../../organisms';
import useStyles from './AnalyticsListingsPage.styles';
import HomeIcon from '../../../../../../images/icons/Home';
import ViewsIcon from '../../../../../../images/icons/Views';
import PhoneCallIcon from '../../../../../../images/icons/PhoneCall';
import EmailIcon from '../../../../../../images/icons/Email';
import StarIcon from '../../../../../../images/icons/Star';
import ConversationIcon from '../../../../../../images/icons/Conversation';
import AnalyticsPropertyCard, {
  AnalyticsPropertyCardProps,
} from '../../../../../organisms/AnalyticsPropertyCard';
import { getRangeArray } from '../../../../../../utils/array';

const analyticsPropertyCardProps: AnalyticsPropertyCardProps = {
  developmentId: 1,
  title: '17 Sutton Court Road',
  rentPcm: 1000,
  imgThumbnailSrc:
    'https://storage.googleapis.com/btr-orgfiles-9bd15a4e-69d5-4475-b013-5321618d4228/114/1176063b-fc16-41b3-9088-5f4867e21375/123723.jpg',
  promotion: '30-day Rent Free',
  postcode: 'SM1 4GQ',
  address: 'Sutton Court Road',
  analytics: {
    viewCount7day: 32,
    viewCountChange7day: 29,
    leadCount7day: 59,
    leadCountChange7day: -32,
    averageEngagementTime7day: 52,
    averageEngagementTimeChange7day: 16,
  },
};

export default function AnalyticsOverallListing() {
  const classes = useStyles();

  return (
    <Layout>
      <SEO title="Listings" />
      <GenericPage>
        <Box className={classes.ctn}>
          <Box className={classes.contentCtn}>
            <Box className={classes.listings}>
              <Box mb={2.5}>
                <Typography
                  color="primary"
                  fontWeight={600}
                  textTransform="uppercase"
                  className={classes.title}
                >
                  Listings
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <AnalyticDetailLongCard
                    title="All developments"
                    icon={<HomeIcon />}
                    content={235}
                    description={
                      <Box>
                        <Typography
                          className={classes.greenText}
                          fontSize={14}
                          fontWeight={600}
                          component="span"
                        >
                          235
                        </Typography>{' '}
                        <Typography fontSize={12} component="span">
                          Public Listings
                        </Typography>
                      </Box>
                    }
                  />
                </Grid>
                <Hidden mdUp>
                  <Grid item xs={12}>
                    <Box className={classes.cardHorizontal}>
                      {getRangeArray(0, 3).map((val) => (
                        <AnalyticListingCard
                          className={classes.card}
                          key={val}
                          title="Developments in London"
                          content={235}
                          description="Public Listings"
                        />
                      ))}
                    </Box>
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  {getRangeArray(0, 3).map((val) => (
                    <Grid key={val} item xs={12} md={3}>
                      <AnalyticListingCard
                        title="Developments in London"
                        content={235}
                        description="Public Listings"
                      />
                    </Grid>
                  ))}
                </Hidden>
                <Grid item xs={12} md={3}>
                  <AnalyticDetailLongCard
                    title="Views"
                    icon={<ViewsIcon />}
                    content={235}
                    selection={[
                      { label: '24 hours', value: 1 },
                      { label: '7 days', value: 2 },
                      { label: '30 days', value: 3 },
                      { label: '1 year', value: 4 },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <AnalyticDetailLongCard
                    title="Calls"
                    icon={<PhoneCallIcon />}
                    content={235}
                    selection={[
                      { label: '24 hours', value: 1 },
                      { label: '7 days', value: 2 },
                      { label: '30 days', value: 3 },
                      { label: '1 year', value: 4 },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <AnalyticDetailLongCard
                    title="Email"
                    icon={<EmailIcon />}
                    content={235}
                    selection={[
                      { label: '24 hours', value: 1 },
                      { label: '7 days', value: 2 },
                      { label: '30 days', value: 3 },
                      { label: '1 year', value: 4 },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <AnalyticDetailLongCard
                    title="Conversion"
                    icon={<ConversationIcon />}
                    subTitle={
                      <Slider
                        className={classes.slider}
                        defaultValue={30}
                        color="secondary"
                        classes={{
                          track: classes.sliderTrack,
                          rail: classes.sliderRail,
                          thumb: classes.sliderThumb,
                        }}
                      />
                    }
                    content={235}
                    selection={[
                      { label: '24 hours', value: 1 },
                      { label: '7 days', value: 2 },
                      { label: '30 days', value: 3 },
                      { label: '1 year', value: 4 },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <AnalyticDetailLongCard
                    title="Data quality"
                    icon={<StarIcon />}
                    content={235}
                    subTitle={
                      <Slider
                        className={classes.slider}
                        defaultValue={30}
                        color="secondary"
                        classes={{
                          track: classes.sliderTrack,
                          rail: classes.sliderRail,
                          thumb: classes.sliderThumb,
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.individualListings}>
              <Box mb={2.5}>
                <Typography
                  color="primary"
                  fontWeight={600}
                  textTransform="uppercase"
                  className={classes.title}
                >
                  Individual listings
                </Typography>
              </Box>
              <Box className={classes.filterCtn}>
                <Box className={classes.propertiesCount}>
                  <Hidden mdUp>
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      color="gray"
                      colorVariant="contrastText"
                    >
                      18 properties
                    </Typography>
                  </Hidden>
                </Box>
                <Box>
                  <Select
                    className={classes.select}
                    wrapClassName={classes.wrapSelect}
                    options={[]}
                    placeholder="Sort by"
                  />
                </Box>
              </Box>
              <Hidden smDown>
                <Grid container className={classes.gridHeading}>
                  <Grid item xs={7} style={{ textAlign: 'left' }}>
                    <Typography
                      fontWeight={600}
                      color="gray"
                      colorVariant="contrastText"
                    >
                      18 properties
                    </Typography>
                  </Grid>
                  <Grid container item xs={5}>
                    <Grid item xs={4}>
                      7-day views
                    </Grid>
                    <Grid item xs={4}>
                      7-day leads
                    </Grid>
                    <Grid item xs={4}>
                      Avg visit time
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Box className={classes.propertiesCtn}>
                <AnalyticsPropertyCard {...analyticsPropertyCardProps} />
                <AnalyticsPropertyCard {...analyticsPropertyCardProps} />
                <AnalyticsPropertyCard {...analyticsPropertyCardProps} />
                <AnalyticsPropertyCard {...analyticsPropertyCardProps} />
              </Box>
            </Box>
          </Box>
          <Hidden mdUp>
            <Pagination showDescription={false} total={50} />
          </Hidden>
        </Box>
      </GenericPage>
    </Layout>
  );
}
