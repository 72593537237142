import * as React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

const Conversation = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon width="21" height="18.857" viewBox="0 0 21 18.857" {...props}>
      <g transform="translate(-89 -277.085)">
        <path
          d="M89,288.519c.086-.392.141-.793.265-1.172a6.105,6.105,0,0,1,2.637-3.264,7.764,7.764,0,0,1,3.669-1.229c.2-.015.261-.086.286-.288a5.645,5.645,0,0,1,1.739-3.44,7.071,7.071,0,0,1,4.547-2.016,7.713,7.713,0,0,1,6.034,2.079,5.43,5.43,0,0,1,1.792,3.494.434.434,0,0,0,.032.094v.9c-.073.36-.125.726-.224,1.079a5.845,5.845,0,0,1-1.262,2.273.636.636,0,0,0-.147.3,2.449,2.449,0,0,0,.631,2.1l.042.045a.4.4,0,0,1,.1.468.413.413,0,0,1-.428.253,4.483,4.483,0,0,1-2.934-1.136.334.334,0,0,0-.341-.076c-.626.132-1.258.241-1.888.353-.12.021-.242.024-.339.033-.1.435-.157.857-.289,1.255a6.27,6.27,0,0,1-3.506,3.8,7.891,7.891,0,0,1-4.826.545c-.369-.066-.728-.178-1.1-.254a.326.326,0,0,0-.244.064,4.574,4.574,0,0,1-2.93,1.157.424.424,0,0,1-.336-.752,2.442,2.442,0,0,0,.647-2.1.629.629,0,0,0-.136-.307,5.781,5.781,0,0,1-1.464-3.229A1.046,1.046,0,0,0,89,289.42Zm2.12,6.492a3.7,3.7,0,0,0,1.783-1.039.459.459,0,0,1,.534-.144,7.758,7.758,0,0,0,2.282.456,6.556,6.556,0,0,0,4.986-1.674,4.8,4.8,0,0,0,.127-7.163,6.272,6.272,0,0,0-4.021-1.775,6.811,6.811,0,0,0-5.46,1.892,4.6,4.6,0,0,0-1.526,3.522,4.9,4.9,0,0,0,1.327,3.205,1.216,1.216,0,0,1,.26.515A3.275,3.275,0,0,1,91.12,295.011Zm12.058-6.463a7.989,7.989,0,0,0,2.333-.445.536.536,0,0,1,.594.128,8.5,8.5,0,0,0,1,.739,5.065,5.065,0,0,0,.78.323c-.072-.172-.126-.276-.159-.386a2.582,2.582,0,0,1,.37-2.7,4.369,4.369,0,0,0,1-3.792,4.824,4.824,0,0,0-1.849-2.96,6.914,6.914,0,0,0-6.586-1.228,5.658,5.658,0,0,0-3.5,2.838,4.324,4.324,0,0,0-.5,1.746c.082.013.147.028.213.033a7.69,7.69,0,0,1,2.677.709.918.918,0,0,0,.372.077q3.116.007,6.233,0a1.59,1.59,0,0,1,.2,0,.408.408,0,0,1,.02.808,1.4,1.4,0,0,1-.225.008h-5.185A5.96,5.96,0,0,1,103.178,288.547Z"
          transform="translate(0)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M153.453,517.871H149.66a1.554,1.554,0,0,1-.238-.008.475.475,0,0,1,.007-.941,1.748,1.748,0,0,1,.238-.006h7.585c.064,0,.127,0,.19,0a.479.479,0,0,1,.453.466.473.473,0,0,1-.46.482c-.238.011-.477,0-.715,0Z"
          transform="translate(-57.042 -226.814)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M182.518,471.888h2.838a1.411,1.411,0,0,1,.214.007.475.475,0,0,1,0,.941,1.648,1.648,0,0,1-.238.007h-5.675c-.055,0-.111,0-.167,0a.481.481,0,0,1-.48-.489.473.473,0,0,1,.481-.462c.366-.009.731,0,1.1,0Z"
          transform="translate(-85.408 -184.228)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M149.5,471.939a.476.476,0,1,1-.469.471A.482.482,0,0,1,149.5,471.939Z"
          transform="translate(-56.207 -184.278)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M306.91,352.842c-.962,0-1.924.006-2.885-.006a.793.793,0,0,1-.432-.147.4.4,0,0,1-.115-.476.457.457,0,0,1,.406-.32,3.809,3.809,0,0,1,.381-.005h5.461c.071,0,.143,0,.215,0a.481.481,0,0,1,.48.465.473.473,0,0,1-.481.486c-.461.009-.922,0-1.383,0Z"
          transform="translate(-203.487 -70.74)"
          fill={theme.palette.primary.main}
        />
        <path
          d="M274,351.939a.476.476,0,1,1-.468.471A.482.482,0,0,1,274,351.939Z"
          transform="translate(-174.506 -70.791)"
          fill={theme.palette.primary.main}
        />
      </g>
    </SvgIcon>
  );
};

export default Conversation;
