import * as React from 'react';
import { Box } from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import { Button, Spacer } from '../../atoms';
import { ButtonWithIcon } from '../../molecules';

import { navigate } from '../../../utils/dom';

import useStyles from './EditPropertyFormLayout.styles';
import { Skeleton } from '@material-ui/lab';
import { ScreenType } from '../../../config';
import useIsScreenType from '../../../utils/dom/useIsScreenType';

export interface EditPropertyFormLayoutProps {
  title: string;

  /**
   * On mobile view, the form's title is clickable and will take the user back
   * to the main edit property page. This URL is used to do that.
   */
  mainPageUrl: string;

  /**
   * The layout can behave differently when data is loading.
   */
  formIsLoading: boolean;

  /**
   * What happens when the 'Save' button is clicked. If not provided, the 'Save'
   * button will not be rendered.
   */
  formSubmitHandler?: () => void;

  /**
   * The layout can behave differently when the submission action is in
   * progress.
   */
  formSubmitIsLoading: boolean;

  children?: React.ReactNode;
}

export default function EditPropertyFormLayout({
  title,
  mainPageUrl,
  formIsLoading,
  formSubmitHandler,
  formSubmitIsLoading,
  children,
}: EditPropertyFormLayoutProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({ isMobile });

  return (
    <Box className={classes.ctn}>
      {isMobile && (
        <>
          <ButtonWithIcon
            text={title}
            icon={<ArrowLeft color="inherit" />}
            onClick={() => navigate(mainPageUrl)}
            color="#fff"
            fullWidth
          />

          <Spacer y={2} />
        </>
      )}

      <Box>
        {formIsLoading ? (
          <Skeleton variant="rect" height={320} animation="wave" />
        ) : (
          children
        )}
      </Box>

      <Spacer y={4} />

      {formSubmitHandler && (
        <Box className={classes.actionCtn}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={formIsLoading || formSubmitIsLoading}
            onClick={formSubmitHandler}
            roundedLevel="high"
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
}
