import * as React from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      height: '100%',
    },
  })
);

// ========== COMPONENT ========== //

export default function GenericLoading(props: CircularProgressProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <CircularProgress {...props} />
    </Box>
  );
}
