import { createStyles, makeStyles, alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    ctn: {
      backgroundColor: 'white',
      padding: theme.spacing(2.5, 2, 1.25, 2),
      borderRadius: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
    info: {
      display: 'flex',
      gap: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
      borderBottom: `1px solid ${alpha(theme.palette.gray.dark || '', 0.2)}`,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
        marginBottom: 0,
        paddingBottom: 0,
      },
    },
    imgCtn: {
      width: 120,
      height: 140,
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        width: 230,
        height: 'auto',
      },
    },
    promotion: {
      backgroundColor: theme.palette.primary.main,
      height: 20,
      position: 'absolute',
      bottom: 0,
      width: '100%',
      textAlign: 'center',
    },
    contentCtn: {
      flex: 1,
      [theme.breakpoints.up('md')]: {
        padding: '1rem',
      },
    },
    title: {
      marginBottom: theme.spacing(1.5),
    },
    address: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2.75),
      '& span': {
        fontSize: '0.75rem',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(1.5),
        '& span': {
          fontSize: 17,
        },
      },
    },
    icon: {
      marginLeft: -2,
      color: theme.palette.gray.dark,
      fontSize: theme.spacing(1.75),
      marginRight: theme.spacing(0.5),
    },
    analytics: {},
    analyticItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    arrowIcon: {
      color: theme.palette.gray.contrastText,
      transform: 'scale(2.5)',
      fontSize: 10,
      marginRight: 4,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    editListing: {
      color: '#3258A8',
    },
    upIcon: {
      color: '#2CA792',
      fontSize: 9,
    },
    downIcon: {
      color: theme.palette.primary.main,
      fontSize: 9,
      transform: 'rotate(180deg)',
    },
    gridCenterBaseLine: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      '& .MuiSvgIcon-root': {
        width: 18,
        height: 18,
        marginRight: 9,
      },
    },
    gridRow: {
      margin: '0 0 8px',
    },
    alignRow: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 16,
      color: theme.palette.gray.dark,
      margin: 0,
      '& svg': {
        fontSize: 14,
      },
    },
    reportCard: {
      backgroundColor: '#EDEDED',
      padding: '24px 0',
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
    },
    gridCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '2px solid #FFFFFF',
      '&:last-child': {
        borderRight: 0,
      },
    },
    titleText: {
      fontSize: '0.875rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.625rem',
      },
    },
    rentPcm: {
      fontSize: '0.75rem',
      [theme.breakpoints.up('md')]: {
        fontSize: 17,
      },
    },
  })
);

export default useStyles;
